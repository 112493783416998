.currentrecipe-container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    font-family: 'Oswald', sans-serif;
    margin: 5px;
}

.post-rating button {
    display: block;
    padding: 10px;
    background-color: #373d20;
    color: #FEFEFE;
    font-family: 'Oswald', sans-serif;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    border: solid 1px black;
}

.post-rating button:hover {
    transform: scale(1.1);
}

.recipe-title {
    font-size: 32px;
    text-decoration: underline;
    color: #080808;
}

.recipe-img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    margin-bottom: 0;
}

.recipe-info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 5px;
}

.recipe-video iframe {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 300px;
    height: 400px;
}
.recipe-times {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.recipe-steps {
    display: block;
    margin-right: 40px;
    margin-left: 40px;
    margin-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
    z-index: 1;
}

@media screen and (max-width: 1080px) and (orientation: portrait) {
    .currentrecipe-container {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        flex-direction: column;
        font-family: 'Oswald', sans-serif;
    }

    .recipe-title {
        font-size: 32px;
        text-decoration: underline;
    }

    .recipe-img {
        width: 400px;
        height: 400px;
        object-fit: cover;
        margin-bottom: 0;
        border-radius: 10px;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .recipe-info-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 5px;
        margin: 0 auto;
    }


    .recipe-video iframe {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        border-radius: 10px;
        width: 500px;
    }
    

    .recipe-times {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .recipe-steps {
        display: block;
        margin-top: 0;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 50px;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
        transform: scale(1.1);
        z-index: 1;
    }
}

.favorite-button button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 75px;
    font-size: 26px;
    text-shadow: "2px 2px 5px 2px #080808";
    font-weight: bold;
}