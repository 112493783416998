.signup-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    background-color: #717744;
}

.signup-form form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-form label {
    font-family: 'Oswald', sans-serif;
    display: flex;
    font-size: 18px;
    flex-direction: column;
    gap: 10px;
}

.signup-form-username input {
    padding: 10px;
    font-family: 'Oswald', sans-serif;
    border-radius: 4px;
    margin: 10px;
}

.signup-form button {
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 80%;
    background-color: #FEFEFE;
    border-radius: 4px;
    border: 2px solid #ABAC7F;
    align-items: center;
    cursor: pointer;
}

.signup-form button:hover {
    background-color: #8F9779;
    color: #FEFEFE;
    border: 2px solid #FEFEFE;
}

.signup-form-username {
    display: flex;
    flex-direction: row;
}

.signup-form-password {
    display: flex;
    flex-direction: column;
}

.signup-form-password input {
    max-width: 500px;
    padding: 10px;
    font-family: 'Oswald', sans-serif;
    border-radius: 4px;
    margin-bottom: 10px;
}