.confirm-delete {
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #717744;
    font-family: 'Oswald', sans-serif;
}

.confirm-delete h1 {
    font-family: 'Oswald', sans-serif;
    align-items: center;
    text-align: center;
}

.confirm-delete label {
    padding: 10px;
}

.confirm-delete div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.confirm-delete button {
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-family: 'Oswald', sans-serif;
    border-radius: 4px;
    margin: 4px 2px;
    cursor: pointer;
}

.confirm-delete button:hover {
    background-color: darkgray;
    color: #FEFEFE;
}