.recipe-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
}

.recipe-homepage h1 {
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-size: 50px;
    color: #080808;
    text-decoration: underline;
    font-weight: bold 700;
    text-decoration-color: #717744;
    padding: 20px;
    margin: 10px;
}

.recipe-homepage p {
    text-indent: 40px;
    font-size: 36px;
    margin: 50px;
    font-family: 'Oswald', sans-serif;
    margin-left: 100px;
    margin-right: 100px;
    padding: 30px;
    text-align: left;
    
}

.recipe-grid {
    display: grid;
    padding: 20px;
    margin: 20px;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.recipe-grid h2 {
    font-family: 'Oswald', sans-serif;
    display: block;
    text-align: center;
}

.recipe-card {
    border: 2px solid #373D20;
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    width: 400px;
    height: 500px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;
    cursor: pointer;
}

.recipe-card img {
    border-radius: 20px;
    box-shadow: 0 0 5px 0 #080808;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 50%;
}

.recipe-card:hover {
    box-shadow: 2px 2px 5px 2px #080808;
    transition: 0.3s;
    transform: scale(1.02);
}

.recipe-link {
    text-decoration: none;
    color: #080808;
}


@media screen and (max-width: 1080px) and (orientation: portrait) {
    .recipe-homepage {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }

    .recipe-homepage h1 {
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-size: 50px;
        color: #080808;
        text-decoration: underline;
        font-weight: bold 700;
        text-decoration-color: #717744;
        padding: 20px;
        margin: 10px;
    }

    .recipe-homepage p {
        text-indent: 40px;
        font-size: 24px;
        margin: 50px;
        font-family: 'Oswald', sans-serif;
        margin-left: 100px;
        margin-right: 100px;
        padding: 30px;
        text-align: left;
    }

    .recipe-grid {
        display: grid;
        padding: 20px;
        margin: 20px;
        grid-template-columns: repeat(2, 1fr); /* Adjusted for 1080px width */
        gap: 20px;
    }

    .recipe-card {
        border: 2px solid #373D20;
        border-radius: 10px;
        max-width: 100%;
        max-height: 100%;
         width: 350px; /* Adjusted for 1080px width */
        height: 450px; /* Adjusted for 1080px width */
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin: 20px;
        cursor: pointer;
    }

    .recipe-card img {
        border-radius: 20px;
        box-shadow: 0 0 5px 0 #080808;
    }

    .recipe-card:hover {
        box-shadow: 2px 2px 5px 2px #080808;
        transition: 0.3s;
        transform: scale(1.02);
    }

    .recipe-link {
        text-decoration: none;
        color: #080808;
    }
}
