.comment-form-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    font-family: "Oswald", sans-serif;
    justify-content: center;
    margin: 20px;
}

.comment-button-container {
    display: flex;
    flex-direction: row;

}
.comment-button-container button {
    align-items: center;
    justify-content: center;
    margin: 4px;
    padding: 10px;
    font-family: "Oswald", sans-serif;
    background-color: #717731;
    border-radius: 4px;
    color: #F0EAD6;
}

.comment-form-container button:hover {
    background-color: #abac7f;
    cursor: pointer;
    color: #000000;
    transition: 0.3s;
    transform: scale3d(1.02, 1.02, 1.02);
}

.comment-form-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    color: #000000;
}

.comment-form-container textarea {
    padding: 10px;
    font-family: 'Oswald', sans-serif;
    width: 400px;
    height: 50px;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: flex-start;
}